<template>
	<el-row style="text-align: center; ">
		<el-image :src="backImg"></el-image>
		<el-button @click="back" type="primary">返回首页</el-button>
	</el-row>
	
</template>

<script>
	import backImg from "assets/images/404.png"; 
	export default {
		data(){
			return {
				backImg: backImg,
			}
		},
		methods:{
			back(){
				this.$router.replace('/');
			}
		},
		mounted(){
			this.url = window.location.host+'/images/404.png'
		}
	}
</script>

<style>
	.el-404{
		padding:9% 25%;
	}
	.bg-purple-404 img{
		width: 100%;
	}
	.grid-content-404{
		margin-top: 35%;
	}
	.grid-content-404 h1{
		margin-bottom: 24px;
		color: #515a6e;
		font-weight: 600;
		font-size: 72px;
		line-height: 72px;
	}
	.ivu-exception-content-desc {
	    margin-bottom: 16px;
	    color: #808695;
	    font-size: 20px;
	    line-height: 28px;
	}
</style>